<template>
  <div>
    <v-row>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <div v-if="!exportStatus">Not found</div>
            <div v-else>
              <div>
                <strong>{{ exportStatus }}</strong> ({{ exportAt }})
              </div>
              <span v-if="postsPending.length" class="px-0">
                <v-icon color="blue darken-1"> mdi-arrow-up-bold </v-icon>
                {{ postsPending.length }}
              </span>
              <span v-if="postsRejected.length" class="px-0">
                <v-icon color="red darken-1">
                  mdi-arrow-u-up-left-bold mdi-flip-v
                </v-icon>
                {{ postsRejected.length }}
              </span>
              <span v-if="postsApproved.length" class="px-0">
                <v-icon color="green darken-1"> mdi-arrow-down-bold </v-icon>
                {{ postsApproved.length }}
              </span>
            </div>
          </span>
        </template>
        <span>
          <div v-if="postsRejected.length == 1">
            {{ postsRejected.length }} post was rejected
          </div>
          <div v-else>{{ postsRejected.length }} posts were rejected</div>
          <div v-if="postsPending.length == 1">
            {{ postsPending.length }} post is pending feedback
          </div>
          <div v-else>{{ postsPending.length }} posts are pending feedback</div>
          <div v-if="postsApproved.length == 1">
            {{ postsApproved.length }} post was approved
          </div>
          <div v-else>{{ postsApproved.length }} posts were approved</div>
        </span>
      </v-tooltip>
      <div class="ma-0" v-if="isReminderNeeded()">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              color="blue darken-1"
              class="pa-1"
              v-on="on"
              @click="reminderDialog.isShow = true"
            >
              mdi-email-arrow-right-outline
            </v-icon>
          </template>
          <span style="font-size: 12px">Send reminder</span>
        </v-tooltip>
      </div>

      <div v-if="isLoading">
        <v-icon color="grey darken-1">mdi-refresh mdi-spin</v-icon>
      </div>
    </v-row>
    <ModalDialog
      :isShow="reminderDialog.isShow"
      title="Send Reminder on pending export"
      :footerButtons="reminderDialog.footerButtons"
      @success="sendReminder(customerId)"
      @cancel="reminderDialog.isShow = false"
      @close="reminderDialog.isShow = false"
    >
      <template v-slot:description>{{
        `Send a Reminder to ${customerName}?`
      }}</template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalDialog from "@/components/global/ModalDialog";

export default {
  components: { ModalDialog },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      reminderDialog: {
        isShow: false,
        footerButtons: [
          { name: "Cancel", emitName: "cancel" },
          { name: "Yes", emitName: "success", btnClass: "violet" },
        ],
      },
    };
  },
  async created() {
    this.isLoading = true;
    await this.updateCustomerExportStatus(this.customerId);
    this.isLoading = false;
  },
  mounted() {
    this.$emit("exportStatusReady");
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    exportStatus() {
      if (!(this.customerId in this.getCustomerExpostStatus())) {
        return null;
      }
      return this.getCustomerExpostStatus()[this.customerId].exportStatus
        .status;
    },
    exportAt() {
      if (!(this.customerId in this.getCustomerExpostStatus())) {
        return null;
      }

      let exportedAtTime =
        this.getCustomerExpostStatus()[this.customerId].exportStatus.exportedAt;
      return this.$dayjs.unix(exportedAtTime).format("DDMMM");
    },
    postsApproved() {
      if (!(this.customerId in this.getCustomerExpostStatus())) {
        return [];
      }
      return this.getCustomerExpostStatus()[this.customerId].exportStatus.posts[
        "approved"
      ];
    },
    postsRejected() {
      if (!(this.customerId in this.getCustomerExpostStatus())) {
        return [];
      }
      return this.getCustomerExpostStatus()[this.customerId].exportStatus.posts[
        "rejected"
      ];
    },
    postsPending() {
      if (!(this.customerId in this.getCustomerExpostStatus())) {
        return [];
      }
      return this.getCustomerExpostStatus()[this.customerId].exportStatus.posts[
        "pending"
      ];
    },
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomerExportStatus"]),
    ...mapGetters("clientSelection", ["getCustomerExpostStatus"]),
    ...mapActions("communication", ["sendMessage"]),
    isReminderNeeded() {
      if (!(this.customerId in this.getCustomerExpostStatus())) {
        return null;
      }

      let exportedAtTime =
        this.getCustomerExpostStatus()[this.customerId].exportStatus.exportedAt;

      if (!exportedAtTime || this.exportStatus !== "pending") {
        return false;
      }

      return exportedAtTime < this.$dayjs().add(-12, "h").unix();
    },
    async sendReminder(customerId) {
      try {
        const params = {
          customerId,
          exportReminder: true,
          smmId: this.getCurrentUser.id,
        };
        await this.sendMessage(params);
      } catch (e) {
        throw e;
      } finally {
        this.reminderDialog.isShow = false;
      }
    },
  },
};
</script>
