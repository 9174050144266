<template>
  <div>
    <v-row>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <div>
              <div>
                <span :class="classToUse" :style="styleToUse">
                  {{ numDaysStr }}
                </span>
              </div>
            </div>
          </span>
        </template>
        {{ lastMessageStr }}
      </v-tooltip>

      <v-tooltip
        bottom
        v-if="
          chatstate &&
          (chatstate.is_auto_reply_notification_active ||
            chatstate.is_auto_reply_active)
        "
      >
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <div>
              <div>
                <span :class="classToUse">
                  <v-icon class="ml-2" color="yellow darken-1">
                    mdi-robot-outline
                  </v-icon>
                </span>
              </div>
            </div>
          </span>
        </template>
        There is currently an active Auto-Reply by the Chat-bot
      </v-tooltip>
      <div v-if="isLoading">
        <v-icon color="grey darken-1">mdi-refresh mdi-spin</v-icon>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    customerId: {
      type: String,
      required: true,
    },
    activityState: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {
    this.isLoading = true;
    const ok = await this.updateCustomerChatstate(this.customerId);
    this.isLoading = false;
  },
  mounted() {
    this.$emit("chatStateReady");
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("clientSelection", ["getCustomerChatstate"]),
    chatstate() {
      if (!(this.customerId in this.getCustomerChatstate)) {
        return null;
      }
      return this.getCustomerChatstate[this.customerId];
    },

    fontSize() {
      return {
        fontSize: 30,
      };
    },

    numDaysStr() {
      if (this.nonResponseValue < 0) return "-";
      if (this.nonResponseValue > 60) return "60+";
      return this.nonResponseValue;
    },

    lastMessageStr() {
      if (!this.chatstate) return "Last message to customer was not found";
      const minutesAgo =
        this.chatstate["time_from_last_message_from_social_wonder_minutes"];
      if (!minutesAgo) return "Last message to customer was not found";
      const lastMEssageTime = this.$dayjs(new Date()).subtract(
        minutesAgo,
        "minute"
      );

      const str = `Last message to customer sent on: ${this.$dayjs(
        lastMEssageTime
      ).format("YYYY-MM-DD HH:mm")}`;
      return str;
    },
    nonResponseValue() {
      if (
        !this.chatstate ||
        !this.chatstate["time_from_last_message_from_social_wonder_minutes"] ||
        this.chatstate["time_from_last_message_from_social_wonder_minutes"] >
          300000
      )
        return -1;
      let workingDaysCount = 0;
      let daysSum = Math.floor(
        (this.chatstate["time_from_last_message_from_social_wonder_minutes"] ||
          0) /
          60 /
          24
      );
      let endDay = this.$dayjs(new Date());

      while (daysSum > 0) {
        const weekday = endDay.weekday();
        if (![6, 0].includes(weekday)) {
          workingDaysCount++;
        }
        endDay = endDay.subtract(1, "day");
        daysSum--;
      }

      return workingDaysCount;
    },
    classToUse() {
      if (this.activityState == "Canceled") return "level1";
      let daysToRed = 4;
      let daysToOrange = 3;
      if (this.activityState == "Trial") {
        daysToRed--;
        daysToOrange--;
      }

      if (this.nonResponseValue >= daysToRed) return "level3";
      if (this.nonResponseValue >= daysToOrange) return "level2";
      return "level1";
    },
    styleToUse() {
      if (this.activityState == "Canceled" || this.nonResponseValue < 0)
        return {};
      return {
        "font-size": 12 + this.nonResponseValue + "px",
        // this.nonResponseValue >= 4
        //   ? 12 + this.nonResponseValue + 'px'
        //   : '12px',
      };
    },
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomerChatstate"]),
  },
};
</script>

<style scoped lang="scss">
.level3 {
  color: red;
  font-weight: bold;
}
.level2 {
  color: orange;
  font-weight: bold;
}
.level1 {
  color: black;
}
</style>
