<template>
  <div>
    <v-row justify="center">
      <v-col cols="11">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-feature-search-outline"
          label="Search"
          single-line
          hide-details
          autofocus
          clearable
        />
        <v-data-table
          :headers="headers"
          :items="customersFiltered"
          :loading="loading && !isClientReadyForSelect"
          :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
          :options="options"
          :mobile-breakpoint="null"
          class="elevation-1"
          item-key="id"
          :search="search"
          :customSort="customSort"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span class="pr-3" v-if="!getCurrentUser.read_only">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" @click="modifyCustomer(item, true)">
                    mdi-pencil-outline
                  </v-icon>
                </template>
                <span style="font-size: 12px">Modify client</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="
                  item.activity_state !== 'Canceled' &&
                  ['customer_deleted', 'subscription_deleted'].includes(
                    item.stripe_state
                  )
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="red"
                    v-on="on"
                    @click="modifyCustomer(item, true)"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <span style="font-size: 12px"
                  >Client cancelled! Modify client to fill cancellation
                  reason</span
                >
              </v-tooltip>
            </span>
            <span>
              <v-avatar
                class="pointer"
                @click="selectCustomer(item)"
                color="primary"
                size="30"
              >
                <img v-if="item.picUrl" :src="item.picUrl" />
                <img v-else src="@/assets/default-profile-picture.png" />
              </v-avatar>
              <span class="pointer px-5" @click="selectCustomer(item)">
                {{ item.name }} - {{ item.personal_name }}
              </span>
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  :color="item.is_automated ? 'red' : 'rgba(0, 0, 0, 0.54)'"
                  v-on="on"
                  @click="
                    item.is_automated
                      ? redirectToSaasApplication(item.id)
                      : selectCustomer(item, true)
                  "
                >
                  mdi-open-in-new
                </v-icon>
              </template>
              <span style="font-size: 12px">Open in new tab</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="icon-container"
                  small
                  :color="item.is_automated ? '#6FC5B0' : '#fffff'"
                  :disabled="isContentGenerating"
                  v-on="on"
                  @click="generateContentHandler(item)"
                >
                  mdi-auto-fix
                </v-icon>
              </template>
              <span style="font-size: 12px">Generate content</span>
            </v-tooltip>
            <v-tooltip v-if="item.is_automated" bottom>
              <template v-slot:activator="{ on }">
                <img
                  v-show="!isShowLoadingForCreateInstagramTemplate"
                  class="icon-container icon-image"
                  src="@/assets/img/icons/instagram-post.png"
                  v-on="on"
                  @click="createInstagramTemplate(item)"
                />
                <v-progress-circular
                  v-show="isShowLoadingForCreateInstagramTemplate"
                  class="icon-container"
                  :size="15"
                  color="#7e5689"
                  indeterminate
                ></v-progress-circular>
              </template>
              <span style="font-size: 12px">Create Instagram Template</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.activity_state`]="{ item }">
            <span>
              {{ getActivityStatusName(item.activity_state) }}
            </span>
          </template>

          <template v-slot:[`header.domain`]="{ header }">
            <v-autocomplete
              v-model="filteringDomain"
              class="small-font"
              :items="getDomainsName"
              clearable
            >
              <template v-slot:label>
                <span class="small-font">{{ header.text }}</span>
              </template>
            </v-autocomplete>
          </template>

          <template v-slot:[`item.domain`]="{ item }">
            <template>
              <span v-if="item.domain">
                {{ item.domain }}
              </span>
            </template>
          </template>

          <template v-slot:[`header.subdomains`]="{ header }">
            <v-autocomplete
              v-model="filteringSubdomains"
              class="small-font"
              :items="optionalSubdomains"
              clearable
            >
              <template v-slot:label>
                <span class="small-font">{{ header.text }}</span>
              </template>
            </v-autocomplete>
          </template>

          <template v-slot:[`header.activity_state`]="{ header }">
            <v-autocomplete
              v-model="filteringActivityState"
              class="small-font"
              :items="optionalActivityState"
              multiple
            >
              <template v-slot:label>
                <span class="small-font">{{ header.text }}</span>
              </template>
            </v-autocomplete>
          </template>

          <template v-slot:[`item.subdomains`]="{ item }">
            <template>
              <span v-if="item.subdomains && item.subdomains.length">
                {{ item.subdomains.join(", ") }}
              </span>
            </template>
          </template>

          <template v-slot:[`header.social_platforms`]="{ header }">
            <v-autocomplete
              v-model="filteringSocialPlatforms"
              class="small-font"
              :items="socialPlatformsItems"
              clearable
            >
              <template v-slot:label>
                <span class="small-font">{{ header.text }}</span>
              </template>
            </v-autocomplete>
          </template>

          <template v-slot:[`item.social_platforms`]="{ item }">
            <template>
              <v-row align-content-sm="center" align="center">
                <v-col
                  v-if="item.social_platforms && item.social_platforms.length"
                >
                  <v-icon
                    v-if="item.social_platforms.find((i) => i == 'facebook')"
                    color="blue darken-1"
                    class="pa-1"
                  >
                    mdi-facebook
                  </v-icon>
                  <v-icon
                    v-if="item.social_platforms.find((i) => i == 'instagram')"
                    color="purple darken-1"
                    class="pa-1"
                  >
                    mdi-instagram
                  </v-icon>
                </v-col>
              </v-row>
            </template>
          </template>

          <template v-slot:[`header.owner`]="{ header }">
            <v-autocomplete
              :value="filteringOwner"
              class="small-font"
              :items="itemsSmm"
              item-value="id"
              item-text="name"
              multiple
              @change="changeOwnerListHandler"
            >
              <template v-slot:label>
                <span class="small-font">{{ header.text }}</span>
              </template>
            </v-autocomplete>
          </template>

          <template v-slot:[`item.owner`]="{ item }">
            {{
              itemsSmm.find((i) => i.id === item.owner_id)
                ? itemsSmm.find((i) => i.id === item.owner_id).name
                : ""
            }}
          </template>
          <template v-slot:[`item.tasks`]="{ item }">
            <span style="color: red; font-weight: bold; font-size: 17px">
              {{
                getTasksList.find((i) => i.customerId === item.id) &&
                getTasksList.find((i) => i.customerId === item.id).count
                  ? getTasksList.find((i) => i.customerId === item.id).count
                  : ""
              }}
            </span>
          </template>
          <template v-slot:[`item.rush_post`]="{ item }">
            <v-icon v-if="item.rush_post"> mdi-check-bold</v-icon>
          </template>
          <template v-slot:[`item.posts`]="{ item }">
            <ExpostStatus
              :customerId="item['id']"
              :customerName="`${item['name']} - ${item['personal_name']}`"
              @exportStatusReady="item.isReadyExportStatus = true"
            />
          </template>

          <template v-slot:[`item.communication`]="{ item }">
            <Chatstate
              :customerId="item['id']"
              :activityState="item['activity_state']"
              @chatStateReady="item.isReadyChatState = true"
            />
          </template>

          <template v-slot:[`item.post_anyway`]="{ item }">
            <v-icon>
              {{
                item.postAnyway === 1
                  ? "mdi-check-bold"
                  : item.postAnyway === 2
                  ? "mdi-close"
                  : "mdi-help"
              }}
            </v-icon>
          </template>

          <template v-slot:[`item.last_visited`]="{ item }">
            <v-chip
              v-if="currentTimeInSeconds"
              :color="
                hoursDiff(currentTimeInSeconds, item.last_visited) >= 143
                  ? 'red'
                  : 'green'
              "
              dark
            >
              {{ hoursDiff(currentTimeInSeconds, item.last_visited) }}h
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalDialog
      :isShow="generateContentDialog.isShow"
      :title="generateContentDialogTitle"
      :isLoading="generateContentDialog.isLoading"
      :footerButtons="generateContentDialog.footerButtons"
      @success="generateContentApprove"
      @close="closeGeneratePopup"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import ExpostStatus from "@/components/global/ExpostStatus";
import Chatstate from "@/components/global/Chatstate";
import {
  SUPPORTED_SOCIAL_PLATFORMS,
  ACTIVITY_STATE,
  EXCLUDED_OWNERS,
} from "@/constants/globals";
import { mapActions, mapMutations, mapGetters } from "vuex";
import ModalDialog from "@/components/global/ModalDialog.vue";
import Customer from "@/models/Customer";
//import LogRocket from "logrocket";

export default {
  name: "ClientSelections",
  components: { ModalDialog, ExpostStatus, Chatstate },
  data: () => ({
    search: "",
    generateContentDialog: {
      isShow: false,
      size: 700,
      isLoading: false,
      footerButtons: [
        { name: "Cancel", emitName: "cancel" },
        { name: "OK", emitName: "success", btnClass: "violet" },
      ],
    },
    customerForGenerateContent: new Customer(),
    headers: [
      {
        text: "id",
        value: "id",
        align: " d-none",
      },
      {
        text: "personal_name",
        value: "personal_name",
        align: " d-none",
      },
      {
        text: "Client Name",
        align: "start",
        value: "name",
        width: "40%",
      },
      { text: "Posts status", value: "posts", width: "15%", sortable: false },
      {
        text: "Tasks",
        value: "tasks",
        width: "5%",
        sortable: false,
      },
      {
        text: "Last message",
        value: "communication",
        width: "5%",
        sortable: false,
      },
      {
        text: "Post Anyway",
        value: "post_anyway",
        width: "5%",
        sortable: false,
      },
      {
        text: "Rush Post",
        value: "rush_post",
        width: "5%",
        sortable: false,
      },
      { text: "Domain", value: "domain", width: "5%", sortable: false },
      {
        text: "Subscription Status",
        value: "activity_state",
        width: "5%",
        sortable: false,
      },
      /* {
        text: "Subdomains",
        value: "subdomains",
        width: "10%",
        sortable: false,
        align: " d-none",
      },*/
      /* {
        text: "Socials",
        value: "social_platforms",
        width: "10%",
        sortable: false,
        align: " d-none",
      },*/
      {
        text: "Owner",
        value: "owner",
        width: "3%",
        sortable: false,
      },
      // { text: "Last Updated", value: "last_visited" },
      // { text: "Unread Messages", value: "unread_messages" },
    ],
    customers: [],
    currentCustomer: {},
    currentTimeInSeconds: null,
    rowsPerPageItems: [1, 5, 10, 20, 100],
    paginationOptions: {
      page: 1,
      itemsPerPage: 1000,
    },
    options: {
      sortBy: ["domain", "activity_state", "name"],
      sortDesc: [true, false, false],
      multiSort: true,
      itemsPerPage: 1000,
    },
    totalItems: null,
    loading: false,
    filteringDomain: null,
    filteringSubdomains: null,
    filteringActivityState: ["Trial", "Active", "Testing"],
    filteringSocialPlatforms: null,
    filteringOwner: [],
    itemsSmm: [],
    isShowLoadingForCreateInstagramTemplate: false,
    isContentGenerating: false,
  }),
  async mounted() {
    this.getOwnerListFromCookies();
    await this.fetchTasks();
    await this.getSmmList();
    //this.filteringOwner = [...this.getCurrentUser.id, ...this.filteringOwner];
    if (!this.getCustomersArray.length) {
      await this.getCustomers();
    }
    this.loading = false;

    await this.refreshCurrentCustomer();
    await this.fetchDomainsTree();
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("globals", ["getDomainsName"]),
    ...mapGetters("clientSelection", ["getCustomersArray"]),
    ...mapGetters("task", ["getTasksList"]),
    optionalActivityState() {
      return ACTIVITY_STATE.map((i) => i.name);
    },
    generateContentDialogTitle() {
      return (
        "Generate 3 pieces of AI-suggested Posts for " +
        this.customerForGenerateContent.name
      );
    },
    optionalSubdomains() {
      let tmpSet = new Set();
      this.customersFiltered.map((customer) =>
        customer.subdomains.map((sd) => tmpSet.add(sd))
      );
      return [...tmpSet];
    },
    socialPlatformsItems() {
      return SUPPORTED_SOCIAL_PLATFORMS.map((sp) => sp.name);
    },
    customersFiltered() {
      let customersFilteredDic = this.getCustomersArray;
      if (this.filteringDomain) {
        customersFilteredDic = customersFilteredDic.filter(
          (c) => c.domain == this.filteringDomain
        );
      }
      if (this.filteringSubdomains) {
        customersFilteredDic = customersFilteredDic.filter(
          (c) =>
            c.subdomains.length &&
            c.subdomains.includes(this.filteringSubdomains)
        );
      }
      if (this.filteringSocialPlatforms) {
        customersFilteredDic = customersFilteredDic.filter(
          (c) =>
            c.social_platforms.length &&
            c.social_platforms.includes(this.filteringSocialPlatforms)
        );
      }
      if (this.filteringActivityState) {
        customersFilteredDic = customersFilteredDic.filter(
          (c) =>
            this.filteringActivityState.includes(c.activity_state) ||
            !c.activity_state
        );
      }
      if (this.filteringOwner) {
        customersFilteredDic = customersFilteredDic.filter((c) =>
          this.filteringOwner.includes(c.owner_id)
        );
      }
      return customersFilteredDic;
    },
    isClientReadyForSelect() {
      return this.customersFiltered.every(
        (customer) => customer.isReadyExportStatus && customer.isReadyChatState
      );
    },
  },
  methods: {
    ...mapMutations("clientSelection", [
      "setCookiesCustomerInformation",
      "refreshCurrentCustomer",
    ]),
    ...mapActions("task", ["fetchTasks"]),
    ...mapActions("clientSelection", ["fetchCustomers", "fetchCustomerInfo"]),
    ...mapActions("globals", ["fetchDomainsTree"]),
    ...mapActions("recommendations", [
      "createPostFromRecommendation",
      "createSaasSmbPosts",
    ]),
    ...mapActions("clientForm", ["fetchSmmList"]),
    ...mapMutations("clientSelection", [
      "setCurrentCustomer",
      "setCustomerShowPopupTasks",
    ]),

    async getSmmList() {
      try {
        const data = await this.fetchSmmList();
        if (data) {
          let mainOwnersList = data.filter(
            (owner) => !EXCLUDED_OWNERS.find((item) => item === owner.name)
          );
          let subOwnersList = data.filter((owner) =>
            EXCLUDED_OWNERS.find((item) => item === owner.name)
          );
          this.itemsSmm = [
            ...mainOwnersList.sort((a, b) => (a.name > b.name ? 1 : -1)),
            ...subOwnersList.sort((a, b) => (a.name > b.name ? 1 : -1)),
          ];
        }
      } catch (e) {
        throw e;
      }
    },
    getActivityStatusName(activityValue) {
      const names = ACTIVITY_STATE.filter((i) => i.name == activityValue).map(
        (i) => i.name
      );
      return (names.length && names[0]) || "";
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (a["activity_state"] != b["activity_state"]) {
          if (!isDesc[1]) {
            // if (a["activity_state"] == null) return 1;
            // if (b["activity_state"] == null) return -1;
            if (b["activity_state"] == "Canceled") return -1;
            // if (b["activity_state"] == "Trial") return 1;

            if (a["activity_state"] == "Canceled") return 1;
            // if (a["activity_state"] == "Trial") return -1;
          }
        }
        if (a["domain"] != b["domain"]) {
          if (!isDesc[0]) {
            return a["domain"] < b["domain"] ? -1 : 1;
          } else {
            return b["domain"] < a["domain"] ? -1 : 1;
          }
        }

        if (a["name"] != b["name"]) {
          if (!isDesc[2]) {
            return a["name"] < b["name"] ? -1 : 1;
          } else {
            return b["name"] < a["name"] ? -1 : 1;
          }
        }
      });
      return items;
    },
    modifyCustomer(user) {
      let routeData = this.$router.resolve({
        name: "modify-client",
        query: { customerId: user.id },
      });
      window.open(routeData.href, "_blank");
    },

    async setCustmerDetails(customerId) {
      const clientInfo = await this.fetchCustomerInfo(customerId);
      // this.setCurrentCustomer(clientInfo);
      return clientInfo;
    },

    async selectCustomer(user, newTab = false) {
      this.currentCustomer = user;
      // LogRocket.startNewSession();
      /*LogRocket.identify(this.currentCustomer.id, {
        name: `${this.currentCustomer.name}    ${this.currentCustomer.id}`,
        email:
          this.getCurrentUser.email ||
          `${this.getCurrentUser.name}@social-wonder.com`,
        environment: process.env.VUE_APP_ENVIRONMENT,
        smm: this.getCurrentUser.name,
      });*/
      this.setCookiesCustomerInformation(this.currentCustomer);

      const clientInfo = await this.setCustmerDetails(this.currentCustomer.id);
      this.setCustomerShowPopupTasks(clientInfo.showPopupTasks);

      let nextRoute = "calendar";

      if (newTab) {
        let routeData = this.$router.resolve({
          name: nextRoute,
          // query: { data: "" },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({ name: nextRoute });
      }
    },
    async getCustomers() {
      this.loading = true;
      this.customers = [];
      this.currentTimeInSeconds = null;
      const { data, meta, currentTime } = await this.fetchCustomers({
        id: this.getCurrentUser.id,
        paginationOptions: this.paginationOptions,
      });
      this.customers = data;
      this.currentTimeInSeconds = currentTime;
      this.totalItems = meta.total;
      this.loading = false;
    },
    redirectToSaasApplication(id) {
      const routeData = this.$router.resolve({
        name: "profile-settings",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },

    hoursDiff(currentTime, lastVisited) {
      return Math.trunc((currentTime - lastVisited) / 3600);
    },
    getOwnerListFromCookies() {
      const list = Cookies.get("ownerList");

      if (list) {
        const jsonList = JSON.parse(list);
        const currentUserInJson = jsonList.find(
          (i) => i.id === this.getCurrentUser.id
        );

        if (currentUserInJson) {
          if (currentUserInJson.ownersList.length) {
            this.filteringOwner = currentUserInJson.ownersList;
          } else {
            this.changeOwnerListHandler([this.getCurrentUser.id]);
            this.getOwnerListFromCookies();
          }
        } else {
          Cookies.set("ownerList", [
            ...jsonList,
            {
              id: this.getCurrentUser.id,
              ownersList: [this.getCurrentUser.id],
            },
          ]);
          this.getOwnerListFromCookies();
        }
      } else {
        Cookies.set("ownerList", [
          {
            id: this.getCurrentUser.id,
            ownersList: [this.getCurrentUser.id],
          },
        ]);
        this.getOwnerListFromCookies();
      }
    },
    changeOwnerListHandler(owner) {
      const list = JSON.parse(Cookies.get("ownerList"));

      Cookies.set("ownerList", [
        ...list.filter((i) => i.id !== this.getCurrentUser.id),
        {
          id: this.getCurrentUser.id,
          ownersList: owner,
        },
      ]);

      this.filteringOwner = owner;
    },
    generateContentHandler(customer) {
      this.customerForGenerateContent = customer;
      this.generateContentDialog.isShow = true;
    },
    async createInstagramTemplate(customer) {
      try {
        this.isShowLoadingForCreateInstagramTemplate = true;
        await this.createSaasSmbPosts({
          customer_id: customer.id,
          task: "weekly_content",
          by_smm: true,
        });
      } catch (e) {
        throw e;
      } finally {
        this.isShowLoadingForCreateInstagramTemplate = false;
      }
    },
    async generateContentApprove() {
      try {
        this.generateContentDialog.isLoading = true;
        this.isContentGenerating = true;
        await this.createPostFromRecommendation({
          customerId: this.customerForGenerateContent.id,
          task: "generate",
          by_smm: true,
        });
        this.generateContentDialog.isLoading = false;
        this.generateContentDialog.isShow = false;
        this.isContentGenerating = false;
      } catch (e) {
        throw e;
      }
    },
    closeGeneratePopup() {
      this.generateContentDialog.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
  margin-bottom: unset;
}
.small-font {
  font-size: 12px;
}
.v-data-table-header__icon {
  opacity: 1;
}
.v-data-table-header th {
  white-space: nowrap;
}
.icon-container {
  margin-left: 10px;
}
.icon-image {
  width: 100%;
  max-width: 16px;
}
</style>
